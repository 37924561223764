<template>
  <v-dialog
    :value="show"
    class="grey darken-2"
    max-width="1200"
    overlay-opacity=".85"
    persistent
  >
    <v-card class="grey darken-4 px-0">
      <v-card-title class="grey darken-3 mb-3 py-1">
        <span class="white--text text-overline">
          {{ title }} - {{ images[activeImage].alt }}
        </span>
        <v-spacer />
        <v-btn
          icon
          color="white"
          @click="$emit('close')"
        >
          <v-icon>fa fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            :cols="images.length > 1 ? '' : 12"
            class="d-flex align-center justify-center"
          >
            <div>
              <v-fade-transition
                hide-on-leave
                leave-absolute
              >
                <!-- <div
                    data-blink-ops="scale-crop: 1000x1000; scale-crop-position: smart_objects_points; offset: 0"
                    :data-blink-uuid="images[activeImage].uuid"
                    :key="images[activeImage].uuid"
                    :alt="images[activeImage].alt"
                    @click="nextImage"
                    class="mx-auto"
                    style="height: 65vh;"
                  /> -->
                <!-- data-blink-ops="scale-crop: 1000x1000; scale-crop-position: smart_objects_points; offset: 0" -->
                <!-- :data-blink-ops="images[activeImage].mode && images[activeImage].mode === 'portrait' ? 'scale-crop: 480x720;scale-crop-position: smart_objects_points; offset: 0' : 'scale-crop: 900x505;scale-crop-position: smart_objects_points; offset: 0'" -->
                <div>
                  <img
                    :data-blink-ops="images[activeImage].mode && images[activeImage].mode === 'portrait' ? 'scale-crop: 576x1024;scale-crop-position: smart_objects_points; offset: 0' : 'resize: 1024;scale-crop-position: smart_objects_points; offset: 0'"
                    :data-blink-uuid="images[activeImage].uuid"
                    :key="images[activeImage].uuid"
                    :alt="images[activeImage].alt"
                    class="rounded mx-auto d-block"
                    height="auto"
                    :style="`max-width: ${images[activeImage].mode === 'portrait' ? '480px' : '800px'}; max-height: 700px;`"
                    @click="nextImage"
                  />
                </div>
              </v-fade-transition>
            </div>
          </v-col>
          <template v-if="images.length > 1">
            <v-col cols="2" md="1" lg="2">
              <v-virtual-scroll
                max-height="700"
                :item-height="$vuetify.breakpoint.mdOnly ? '80' : '160'"
                :items="images"
                :bench="images.length"
              >
                <template v-slot:default="{ item, index }">
                  <v-sheet
                    :key="item.uuid"
                    class="grey darken-4"
                  >
                    <div class="rounded">

                      <img
                        class="rounded"
                        data-blink-ops="scale-crop: 125x125"
                        :data-blink-uuid="item.uuid"
                        width="85%"
                        height="auto"
                        @click="activeImage = index"
                      />
                    </div>
                  </v-sheet>
                </template>
              </v-virtual-scroll>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    images: {
      type: Array,
      required: true
    },
    initialImage: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      activeImage: 0
    }
  },
  methods: {
    nextImage () {
      const l = this.images.length
      if (this.activeImage !== l - 1) {
        ++this.activeImage
      } else {
        this.activeImage = 0
      }
    }
  },
  watch: {
    initialImage: function () {
      this.activeImage = this.initialImage
    }
  },
  created () {
    this.activeImage = this.initialImage
  }
}
</script>
